@import "scrollbar";
@import "/src/Style/fun";

* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @include scrollbar;
}

body {
  background-color: #fff;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  border: none;
}

img {
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  text-align: left;
  vertical-align: middle;
}

img {
  vertical-align: middle;
}


:root:root {
  --theme-color: #ff8531;
  --adm-font-size-9: 3.7333333333vmin;
  --adm-color-primary: var(--theme-color);
}
