@import "/src/Style/fun";
.root{
  border-left: 4px solid var(--adm-color-primary);
  font-size: rpx(36);
  font-weight: bold;
  line-height: 1;
  padding-left: rpx(10);
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
