.phone{
  color: var(--tips-color);
}
.pd{
  padding: 30px 15px;
}
.btn{
}

.hide{
  display: none;
}
