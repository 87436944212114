.btn{

  label{
    border-radius: 4px;
    padding: 0;
    background: #ededed;
    width: 60px;
    height: 60px;
    color: #999;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
