.phone{
  color: var(--tips-color);
}
.pd{
  padding:15px;
}
.titlePd{
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn{
}

.root{
  padding: 15px;
  background: #f9f9f9;
  min-height: 100vh;
}

.form{
  background: #fff;
  :global{
    .adm-list-header{
      padding-right: 0;
    }
  }
}

.header{
  display: flex;
  align-items: center;
  gap: 15px;
  background: var(--adm-color-primary);
  padding:0 15px;
  padding-right: 0;
  color: #fff;
  height: 40px;
}
.label{
  flex: 0 0 5em;
}
.delete{
  font-size: 22px;
  color: #fff;
  display: block;
}

.deleteBox{
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: var(--adm-color-danger);
}

.arrow{
  flex: 1;
  font-size: 18px;
  margin-top: 2px;
}
.arrow{
  transition: .2s;
}
.down{
  transform: rotateZ(180deg);
}
