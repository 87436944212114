
@mixin scrollbar($width: 6px){

  &::-webkit-scrollbar-thumb{
    width: $width;
    background: rgba(0,0,0,.12);
    border-radius: 3px;
  }

  &::-webkit-scrollbar{
    width: $width;
    background: transparent;
  }

}
