.root{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.img{
  position: relative;
}
.close{
  border-radius: 100%;
  font-size: 18px;
  color: #de0862;
  z-index: 11;
  position: absolute;
  right: 0;
  background: #fff;
  top:0
}
.upList{
  width: 100%;
}
