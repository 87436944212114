.root {
  padding-bottom: 15px;
}

.p {
  font-size: 12px;
  color: #666;
  text-align: justify;
  line-height: 1.5;
}

.p2{
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #fff;
  background: var(--adm-color-primary);
  border-radius: 20px;
  line-height: 20px;
  height: 20px;
  padding: 0 10px;
  display: inline-block;
}
